// MEDIAQUERIES
$mediaqueries: (
	hover: 			"(hover: hover)",
	ie: 			"screen and (-ms-high-contrast: active), (-ms-high-contrast: none)",
	safari: 		"screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)",
	safari2: 		"not all and (min-resolution:.001dpcm)",
	landscape:		"(orientation: landscape)",
	portrait:		"(orientation: portrait)",
	mobS3: 			360,
	mobS2: 			480,
	mobS: 			640,
	mob: 			767,
	tabS: 			820,
	tab: 			991,
	lapS3: 			1024,
	lapS2: 			1199,
	lapS: 			1280,
	lap: 			1367,
	deskS: 			1440,
	desk: 			1560,
	deskB: 			1680
);

//RESET
*{
    margin: 0;
    padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

p, a, h1, h2, h3, h4, h5, h6, label, button{
    font-family: 'Alfarn', 'arial';
    font-size: 16px;
    font-weight: 400;
}
a{
    text-decoration: none;
}
input{
	focus-visible{
		outline: none;
	}
}
body {
	&.removeHeight {
		overflow: hidden;
		.min-h-screen {
			transform: none !important;
		}
	}

	& .no-scroll {
		height: 100vh;
		overflow: hidden;
	}
}

//VARIABLES
$widthLimit: 1450px;

$white: #FFFFFF;
$black: #000000;
$yellowP: #FFED02;
$yellowS: #FFF200;
$blueP: #005DA6;
$blueLight: #00A0DC;