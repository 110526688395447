.containerSku{
    &__single{
        width: 100%;
        // height: 100vh;
        position: relative;
        padding-top: 30px;
        @include mq(lapS,min) {
            padding-top: 120px;
        }
        picture{
            img{
                object-fit: cover;
            }
        }
        @include mq(mob){
            height: auto; 
            // padding-bottom: 50px;
        }
        &:first-child{
            padding-top: 150px;
            // height: calc(100vh + 100px);
            @include mq(mob){
                // padding-top: 22px;
                // height: calc(125vh + 100px);
                flex-direction: column;
                justify-content: center;
            }
        }
        &:last-child{
            // height: calc(100vh + 100px);
            @include mq(mob){
                // height: calc(125vh + 100px);
                flex-direction: column;
                justify-content: center;
            }
        }
        &--bk{
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
            z-index: 0;
            background-size: cover;
            background-position: center !important;
        }
        &__content{
            width: 100%;
            // height: 100vh;
            @include flex(flex, space-between, center, center);
            position: relative;
            z-index: 1;
            @include mq(mob){
                // height: calc(125vh + 100px);
                flex-direction: column;
                justify-content: center;
            }
            &--left{
                width: 48%;
                @include flex(flex, flex-start, flex-start, flex-start);
                flex-direction: column;
                @include mq(tab){
                    width: 48%;
                }
                @include mq(mob){
                    width: 100%;
                    text-align: center;
                }
                flex-wrap: unset;
                .bktextcontent{
                    width: 100%;
                    float: left;
                    text-align: center;
                    align-self: flex-end;
                    .logosgroup{
                        width: 100%;
                        margin: 0 auto 20px;
                        img{
                            width: 100%;
                        }
                    }
                }
                .bkTitle{
                    margin: 0 auto !important;
                    width: 100%;
                    img{
                        width: 100%;
                    }
                }
                &--desc{
                    font-family: 'Iron-Brine';
                    font-size: 23px;
                    line-height: 30px;
                    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
                    color: $white;
                    padding-bottom: 30px;
                    width: 100%;
                    margin: 0 auto;
                    span{
                        font-size: 15px;
                        display: inline-block;
                    }
                    @include mq(mob){
                        font-size: 22px;
                        line-height: 28px;
                    }
                }
                .boxDesc{
                    @include flex;
                    position: relative;
                    width: 240px;
                    height: 70px;
                    margin: 40px 0 30px;
                    @include mq(mob){
                        margin: 40px auto 30px;
                    }
                    &--img{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        @include mq(mob){
                            top: -5px;
                            left: -5px;
                        }
                    }
                    &--desc{
                        font-size: 30px;
                        line-height: 150%;
                        text-transform: uppercase;
                        text-align: center;
                        color: $yellowP;
                        z-index: 1;
                        @include mq(mob){
                            font-size: 25px;
                            line-height: 100%;
                        }
                    }
                }
                &--ingredients{
                    font-size: 22px;
                    line-height: 150%;
                    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
                    color: $white;
                    margin-bottom: 50px;
                    @include mq(mob){
                        text-align: left;
                        width: 85%;
                        margin: 0 auto 50px;
                        font-size: 16px;
                    }
                }
                .btn{
                    margin: 0 auto;
                    font-size: 20px;
                    @include mq(mob){
                        margin: 0 auto;
                        font-size: 18px;
                        // height: 56px;
                    }
                }
            }
            &--right{
                width: 48%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                height: auto;
                @include mq(tab){
                    width: 48%;
                }
                @include mq(mob){
                    width: 100%;
                    order: -1;
                    text-align: center;
                }
                &--product{
                    width: 80%;
                    padding-top: 30px;
                    animation: floatSpace 7s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
                    filter: drop-shadow(-50px 50px 30px rgba(0, 0, 0, 0.6));
                    @include mq(tab){
                        width: 90%;
                    }
                    @include mq(mob){
                        width: 60%;
                        margin: 0 auto;
                        padding-top: 0;
                    }
                }
            }
        }
        &:nth-child(2n){
            .containerSku__single__content--right{
                order: -1;
                align-self: self-end;
                img{
                    float: right;
                }
            }
            .containerSku__single__content--right--product{
                animation-delay: .5s;
                animation-direction: reverse;
            }
            .containerSku__single__content--left{
                .bktextcontent {
                    align-self: flex-start;
                }
            }
        }
        .detodito-container{
            width: 90%;
            max-width: 1280px;
            height: auto;
            padding-bottom: 60px;
            @include mq(lapS,min) {
                padding-bottom: 120px;
            }
        }
    }
}