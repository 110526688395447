.containerAwards{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    &--imgLeft{
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        left: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob){
            left: 0;
        }
    }
    &--imgRight{
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        right: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob){
            right: 0;
        }
    }
    .iconsAwards{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        position: relative;
        &.container--limit{
            max-width: 100%;
        }
        .bkTitle {
            h1, h2 {
                &::before {
                    top: -15px;
                }
            }
            &--title {
                &.desktop {
                    display: none;
                    @include mq(mob,min) {
                        display: block;
                    }
                }
                &.mobile {
                    @include mq(mob,min) {
                        display: none;
                    }
                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }
        }
        &--money{
            @include flex;
            flex-direction: column;
            position: absolute;
            top: 10px;
            right: 100px;
            @include mq(tab){
                display: none;
            }
            .boxMoney--content{
                width: 230px;
                height: 145px;
                position: relative;
                // margin-bottom: 30px;
                bottom: -30px;
                left: 55px;
                &--bk{
                    position: absolute;
                    left: -15px;
                    top: -45px;
                }
                &--points{
                    z-index: 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    height: 53%;
                    margin-top: 10px;
                    img{
                        width: 40px;
                        height: 40px;
                    }
                    .points{
                        font-size: 43px;
                        line-height: 100%;
                        color: #0056B6;
                        margin-left: 20px;
                        font-family: 'Iron-Brine';
                    }
                }
                &--copy{
                    font-size: 24px;
                    line-height: 36px;
                    color: #0056B6;
                    z-index: 0;
                    position: relative;
                    height: 40%;
                    text-align: center;
                    text-transform: uppercase;
                    @include flex;
                    font-family: 'Iron-Brine';
                    &::before{
                        content: '';
                        position: absolute;
                        top: -10px;
                        left: 0;
                        width: 75%;
                        height: 2px;
                        background: #0056B6;
                        right: 0;
                        margin: 0 auto;
                    }
                }
            }
        }
        &--title{
            font-family: 'Iron-Brine';
            font-size: 24px;
            line-height: 28px;
            text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
            color: $white;
            width: 90%;
            max-width: 500px;
            text-align: center;
            margin: 40px auto;
            span{
                color: $yellowP;
            }
            @include mq(tab){
                // font-size: 18px;
                // margin: 20px 0;
                // width: 80%;
                // margin: 0 auto;
            }
            @include mq(tab,min){
                font-size: 30px;
                line-height: 34px;
                max-width: 450px;
            }
            @include mq(lapS,min){
                max-width: 570px;
            }
        }
        .carouselAwards{
            width: 95%;
            margin: 0 auto;
            &__content{
                width: 100%;
                // margin-top: 30px;
                // margin-bottom: 30px;
                position: relative;
                &--carousel{
                    // padding: 0px 0 80px;
                    padding: 0px 0 120px;
                    @include mq(mob){
                        width: 90%;
                    }
                    &--single{
                        position: relative;
                        width: 100%;
                        height: auto;
                        @include flex;
                        flex-direction: column;
                        @include mq(tab){
                            margin: 0 auto;
                        }
                        &--bk{
                            width: 100%;
                            height: 100%;
                        }
                        .btn{
                            width: 78%;
                            // height: 50px;
                        }
                        &--money{
                            position: absolute;
                            top: 6%;
                            left: 6%;
                            width: 24%;
                            @include mq(tab){
                                width: 22%;
                            }                  
                            @include mq(mob){
                                top: 40px;
                                left: 40px;
                            }
                            img{
                                width: 100%;
                            }
                            p{
                                text-transform: uppercase;
                                font-size: 8px;
                                line-height: 9px;
                                color: #893A1B;
                                text-align: center;
                                position: absolute;
                                top: 3%;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                display: flex;
                                flex-flow: row wrap;
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                                height: 85%;
                                font-family: "Montserrat";
                                font-weight: bold;
                            }                            
                        }
                    }
                    .swiper-pagination{
                        &-bullet{
                            width: 20px;
                            height: 20px;
                            margin: 0 10px;
                            background-color: $white;
                            opacity: .6;
                            &-active{
                                opacity: 1;
                                
                            }
                        }
                        &.hand {
                            display: none;
                        }
                    }
                    .hand {
                        position: absolute;
                        bottom: 10px;
                        left: 0;
                        width: 100%;
                        text-align: center;
                        img {
                            animation-name: moviRotate;
                            animation-duration: 0.5s;
                            animation-iteration-count: infinite;
                            animation-direction: alternate;
                        }
                        &::before {
                            content: '\279C';
                            display: block;
                            position: relative;
                            top: 15px;
                            left: 5px;
                            color: white;
                            animation-name: moviendrew;
                            animation-duration: 0.5s;
                            animation-iteration-count: infinite;
                            animation-direction: alternate;
                        }
                        @keyframes moviendrew {
                            0% {
                                transform: translateX(0px);
                            }
                            100% {
                                transform: translateX(20px);
                            }
                        }
                        @keyframes moviRotate {
                            0% {
                                transform: rotate(-20deg)
                            }
                            100% {
                                transform: rotate(20deg);
                            }
                        }
                    }
                }
                &--carouseltops{
                    .swiper-slide {
                        height: auto;
                    }
                    .topboxes{
                        width: 100%;
                        position: relative;
                        padding: 20px;
                        min-height: 280px;
                        // height: auto;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        @include mq(mob){
                            height: 100%;
                        }
                        .title{
                            font-family: "Alfarn", "arial";
                            font-size: 25px;
                            text-align: center;
                            padding-bottom: 12px;
                            color:#00248D;
                            &.purple{
                                color:#892890;
                            }
                        }
                        .flaminghot{
                            position: absolute;
                            width: 250px;
                            left: calc(50% - 125px);
                            top: 36px;
                            img{
                                width: 100%;
                            }
                        }
                        .premios{
                            display: flex;
                            align-items: center;
                            justify-items: center;
                            flex-wrap: wrap;
                            gap:8px;
                            justify-content: center;
                            .premio{
                                width: calc(33% - 4px);
                                @include mq(mob){
                                    width: 40%;
                                    margin: 0 30%;
                                }
                                img{
                                    width: 100%;
                                }
                                p{
                                    padding-top: 10px;
                                    color:#005DA6;
                                    font-size: 12px;
                                    text-align: center;
                                }
                            }
                        }
                        &--wall{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            top: 0;
                            left: 0;
                            
                        }
                        .hoho{
                            text-align: center;
                            padding: 15px 0 0;
                            color:#00248D;
                        }
                    }
                    .swiper-pagination{
                        position: inherit;
                        bottom: 0;
                        margin: 20px 0;
                        .swiper-pagination-bullet{
                            width: 25px;
                            height: 25px;
                            background-color: #fff;
                            opacity: .5;
                            margin: 0 10px;
                            &-active{
                                opacity: 1;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
        &--boxNfts{
            @include mq(mob,min){
                margin-top: 30px;
            }
            &--carousel{
                width: 100%;
                height: 600px;
                position: relative;
                @include flex(flex, center, flex-start, flex-start);
                &--bk{
                    width: 100%;
                    height: 80%;
                    position: absolute;
                    top: 10%;
                    left: 0;
                    @include mq(mob){
                        object-fit: cover;
                    }
                }
                &--topbg{
                    width: 100%;
                    height: 15%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    @include mq(mob){
                        object-fit: cover;
                    }
                }
                &--bottombg{
                    width: 100%;
                    height: 20%;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    @include mq(mob){
                        object-fit: cover;
                    }
                }
                .carouselNfts{
                    width: 100%;
                    height: 70%;
                    margin-top: 100px;
                    padding-bottom: 50px;
                    &--single{
                        //min-width: 300px;
                        width: 100%;
                        height: 100%;
                        @include flex;
                        flex-direction: column;
                        @include mq(tab){
                            min-width: 90%;
                            width: 90%;
                            margin: 0 auto;
                        }
                        &--img{
                            // width: auto;
                            width: 100%;
                            height: 80%;
                            // padding: 25px;
                            filter: drop-shadow(15px 15px 17px rgba(0, 0, 0, 0.5));
                            object-fit: contain;
                        }
                        &--desc{
                            font-family: 'Iron-Brine';
                            font-size: 20px;
                            line-height: 23px;
                            color: $blueP;
                            text-shadow: 0px 2.60444px 3.47259px rgba(0, 0, 0, 0.25);
                            height: 20%;
                            text-align: center;
                            display: flex;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                        }
                    }
                    .swiper-pagination{
                        bottom: 30px ;
                        &-bullet{
                            width: 20px;
                            height: 20px;
                            opacity: .6;
                            background-color: $blueP;
                            margin: 0 10px;
                            &-active{
                                opacity: 1;

                            }
                        }
                        @include mq(620, min) {
                            display: none;
                        }
                    }
                }
            }
        }
        
    }
}