.bkTitle{    
    @include flex;
    padding: 20px 0;
    // width: 450px;
    // margin: 0 auto;
    // height: 230px;
    // @include flex;
    // position: relative;
    // @include mq(mob){
    //     width: 100%;
    //     height: 200px;
    // }
    &--title{
        font-weight: 700;
        font-size: 32px;
        line-height: 37px;
        color: $white;
        text-align: center;
        position: relative;
        text-transform: uppercase;
        z-index: 1;
        @include mq(mobS, min) {
            font-size: 46px;
            line-height: 52px;
        }
        @include mq(lapS, min) {
            font-size: 60px;
            line-height: 60px;
        }
        &.blue{
            color: $blueP;
        }
        // @include mq(tab){
        //     font-size: 50px;
        //     line-height: 50px;
        // }
        &::before{
            content: '';
            position: absolute;
            background: url('/img/iconMancha.png');
            left: -60px;
            top: 0px;
            width: 120px;
            height: 120px;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: -1;
            @include mq(tab){
                left: -40px;
                top: 10px;
                width: 90px;
                height: 90px;
            }
        }
        &::after{
            content: '';
            position: absolute;
            background: url('/img/marcorightSuper.png'); 
            width: 150px;
            height: 120px;
            background-size: contain;
            background-repeat: no-repeat;
            top: -20px;
            right: -30px;   
            @include mq(tab){
                top: -15px;
                right: -20px;
                width: 100px;
                height: 70px;
            }          
        }
    }
    // &--img{
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    // }
    .bkText {
        width: 100%;
        max-width: 980px;
        font-family: 'Iron-Brine';
        color: $white;
        margin-top: 30px;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        z-index: 2;
        @include mq(tab,min) {
            font-size: 30px;
            line-height: 34px;
        }
        span {
            color: $yellowP;
        }
    }
}