.containerawardsRedemption{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    &--imgLeft{
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        left: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob){
            left: 0;
        }
    }
    &--imgRight{
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        right: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob){
            right: 0;
        }
    }
    .iconsAwards{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        position: relative;
        .carouselAwards{
            width: 100%;
            &__content{
                width: 100%;
                margin-top: 50px;
                position: relative;
                &--single{
                    position: relative;
                    width: 90%;
                    max-width: 400px;
                    margin: 0 auto;
                    height: auto;
                    @include flex;
                    flex-direction: column;
                    @include mq(tab){
                        margin: 0 auto;
                    }
                    &--bk{
                        width: 100%;
                        height: 100%;
                    }
                    &--money{
                        position: absolute;
                        top: 45px;
                        left: 50px;                       
                        
                        @include mq(mob){
                            top: 40px;
                            left: 40px;
                        }
                        img{
                            width: 100%;
                        }
                        p{
                            text-transform: uppercase;
                            font-size: 8px;
                            line-height: 11px;
                            color: #893A1B;
                            text-align: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            @include flex;
                            height: 56px;
                            font-family: 'Montserrat';
                            font-weight: bold;
                        }                            
                    }
                }
            }            
            &--copy{
                font-size: 32px;
                line-height: 40px;
                font-family: 'Iron-Brine';
                text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
                color: $white;
                margin: 20px 0;
                text-align: center;
            }
            .btn{
                width: 230px;
                // height: 50px;
                margin: 0 auto;
            }
        }
    }
}

.containerawardsForm__content{
    width: 100%;
    min-height: calc(100vh - 300px);
    @include flex;
    &--title{
        font-family: 'Iron-Brine';
        font-size: 20px;
        line-height: 24px;
        color: $white;
        z-index: +1;
        text-align: center;
        // margin-top: 40px;
        margin-bottom: 40px;
        text-transform: uppercase;
        @include mq(tab,min){
            font-size: 25px;
            line-height: 40px;
        }
    }
    .formG{
        width: 70%;
        min-height: 650px;
        @include flex;
        flex-direction: column;
        @include mq(mob){
            width: 100%;
        }
        &--bk{
            // width: 100%;
            // height: 110%;
            // top: 10%;
            // left: 0;
            &.desktop {
                height: 65%;
                top: 20%;
            }
            &.mobile {
                height: 80%;
                top: 10%;
            }
        }
        &__content{
            width: 90%;
            margin: 0 auto;
            padding-top: 0;
            &--btn {
                margin-top: 80px;
            }
        }
        .isAlone {
            .formG__content--input {
                &:only-child {
                    width: 95%;
                }
            }
        }
    }
}
.containersingletext{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    &__content{
        width: 100%;
        position: relative;
        margin-bottom: -145px;
        .detodito-container{
            padding: 30px;
            @include mq(mob){
                padding: 0;
                height: auto;
            }
            .bgmancha{
                position: relative;
                .manchon{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display:block;
                    @include mq(mob){
                        display:none;
                    }
                }
                .manchonmin{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display:none;
                    @include mq(mob){
                        display:block;
                    }
                }
                .texto{
                    width: 85%;
                    margin: 0 auto;
                    padding: 10%;
                    position: relative;
                    @include mq(mob){
                        padding: 10% 5% 300px;
                    }
                    h1{
                        font-size: 25px;
                        color:#fff;
                        text-align: center;
                        padding: 20px 0 30px;
                    }
                    p{
                        color: #FFFFFF;
                        font-family: "Montserrat";
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 600; 
                        padding-bottom: 20px;
                    }
                    ul{
                        padding: 10px 40px 20px;
                        li{
                            color: #FFFFFF;
                            font-family: "Montserrat";
                            font-size: 16px;
                            line-height: 24px;
                            font-weight: 600; 
                        }
                    }
                    a{
                        color:#fff;
                        text-decoration: underline;
                    }
                }
            }
            .space404{
                width: 80%;
                margin: 0 auto;
                text-align: center;
                padding: 30px 0 60px;
                .texto{
                    color:#fff;
                    h1{font-size: 30px;}
                    p{font-size: 20px;}
                }
                .image404{
                    height: 50vh;
                    margin: 0 auto;
                    @include mq(mob){
                        width: 100%;
                        height: auto;
                    }
                }
                .btn{
                    margin: 0 auto;
                }
            }
            .spacety{
                width: 95%;
                margin: 0 auto;
                text-align: center;
                padding: 30px 0 60px;
                .texto{
                    color:#fff;
                    h1{font-size: 30px;}
                    p{font-size: 20px;}
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                    @include mq(mob,min) {
                        max-width: 700px;
                    }
                }
                .imagety{
                    // height: 40vh;
                    width: 100%;
                    max-width: 572px;
                    margin: 0 auto;
                }
                .btn{
                    margin: 50px auto;
                }
            }
        }
    }
    &.tobgtyc{
        background-size: contain;
    }
}
.containeriframegame{
    &__content{
        margin-top: -95px;
        iframe{
            width: 100%;
            height: 100vh;
            position: relative;
            z-index: 9;
            border: none;
        }
    }
}