.containerLogin{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    @include mq(mob){
        padding-bottom: 80px;
    }
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        object-fit: cover;
    }  
    .iconsLogin{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        @include flex;
        flex-direction: column;
        position: relative;
        &--copy{
            font-size: 32px;
            line-height: 120%;
            color: #FFFFFF;
            position: relative;
            margin-bottom: 20px;
            font-family: 'Iron-Brine';
            display: grid;
            text-align: center;
            @include mq(tab){
                font-size: 16px;
                line-height: 28px;
            }
            @include mq(tab,min){
                display: initial;
            }
            span{
                color: $yellowP;
            }
            // &::after{
            //     content: '';
            //     width: 100%;
            //     height: 1px;
            //     background: $white;
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            // }
        }
        .formG{
            width: 100%;
            max-width: 500px;
            // width: 50%;
            // @include mq(mob){
            //     width: 100%;
            // }
            &__content{
                // background: rgba(255, 255, 255, 0.8);
                // border: 1px solid $white;
                // border-radius: 24px;
                flex-direction: column;
                justify-content: center;
                padding: 20px 20px 40px;
                box-sizing: border-box;
                &--input {
                    width: 100%;
                }
                
                &--btn{
                    margin: 40px 0 40px;
                    .copyRemember{
                        position: absolute;
                        bottom: -60px;
                        font-size: 24px;
                        line-height: 120%;
                        color: $white;
                        font-family: 'Anton';
                        left: 0;
                        right: 0;
                        &::after{
                            content: '';
                            position: absolute;
                            bottom: -3px;
                            left: 0;
                            width: 85%;
                            height: 2px;
                            background: $white;
                            right: 0;
                            margin: 0 auto;
                        }
                        @include mq(mob){
                            width: 100%;
                            font-size: 20px;
                            bottom: -50px;
                            left: 0;
                            &::after{
                                width: 230px;
                                right: 0;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}