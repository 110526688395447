@font-face {
    font-family: 'Anton';
    src: url('/fonts/Anton.TTF');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirNext';
    src: url('/fonts/AvenirNext.TTC');
    font-weight: normal;
}
@font-face {
    font-family: 'AvenirNextC';
    src: url('/fonts/AvenirNextCondensed.TTC');
    font-weight: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat.ttf');
    font-weight: normal;
}
@font-face {
    font-family: 'Alfarn';
    src: url('/fonts/Alfarn.otf');
    font-weight: normal;
}
@font-face {
    font-family: 'Iron-Brine';
    src: url('/fonts/Iron-Brine.otf');
    font-weight: normal;
}