.containerRanking{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        object-fit: cover;
    }
    .iconsRanking{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        @include flex;
        flex-direction: column;
        .boxRanking{
            width: 75%;
            margin-top: 30px;
            @include mq(mob){
                width: 100%;
            }
            &__top{
                width: 100%;
                @include flex(flex, space-between, center, center);
                flex-wrap: nowrap;
                @include mq(mob){
                    flex-wrap: wrap;
                }
                &--left{
                    width: 45%;
                    @include mq(mob){
                        width: 100%;
                    }
                    &--copy{
                        font-family: 'Iron-Brine';
                        font-size: 29px;
                        line-height: 37px;
                        text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
                        color: $white;
                        span{
                            color: $yellowP;
                        }
                        @include mq(mob){
                            font-size: 22px;
                            line-height: 25px;
                            text-align: center;
                            margin-bottom: 20px;
                        }
                    }
                }
                &--right{
                    width: 53%;
                    @include mq(mob){
                        width: 100%;
                    }
                    .rankingPersonal{
                        background-color: #A5C200;
                        border-radius: 10px;
                        @include flex(flex, space-between, center, center);
                        flex-wrap: wrap;
                        padding: 25px 20px;
                        @include mq(mob){
                            flex-direction: column;
                            justify-content: center;
                        }
                        &--name{
                            font-weight: 700;
                            font-size: 34px;
                            line-height: 32px;
                            color: $white;
                            padding-right: 10px;
                            width: 320px;
                            @include mq(mob){
                                text-align: center;
                            }
                        }
                        &--scores{
                            width: 40%;
                            @include flex(flex, space-between, center, center);
                            @include mq(mob){
                                width: 100%;
                                margin-top: 20px;
                            }
                            &--single{
                                text-align: center;
                                position: relative;
                                @include mq(mob){
                                    width: 50%;
                                }
                                &--text{
                                    font-weight: 700;
                                    font-size: 18px;
                                    line-height: 18px;
                                    color: $white;
                                    opacity: .5;
                                }
                                &--number{
                                    font-weight: 700;
                                    font-size: 24px;
                                    line-height: 24px;
                                    color: $white;
                                    margin-top: 10px;
                                }
                                &:first-child{
                                    &::after{
                                        content: '';
                                        position: absolute;
                                        top: 0;
                                        right: -25px;
                                        width: 2px;
                                        height: 100%;
                                        background-color: $white;
                                        @include mq(mob){
                                            right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            &--line{
                width: 100%;
                height: 5px;
                background-color: $white;
                margin: 30px 0;
                border-radius: 50px;
            }
            &__middle{
                width: 100%;
                height: auto;
                padding-bottom: 50px;
                position: relative;
                &--arrows{
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 120px;
                    @include flex(flex, space-between, center, center);
                    @include mq(mob){
                        width: 100px;
                        top: -2px;
                    }
                    .btn{
                        border-radius: 6px;
                        padding: 0;
                        width: 50px;
                        height: 50px;
                        line-height: 55px;
                        @include mq(mob){
                            width: 40px;
                            height: 40px;
                            line-height: 45px;
                        }
                        &.disabled{
                            opacity: .7;
                            pointer-events: none;
                        }
                    }
                    &--right{
                        img{
                            transform: rotate(-180deg);
                        }
                    }
                }
                &__single{
                    width: 100%;
                    height: auto;
                    display: block;
                    &.hiddenWeek{
                        display: none;
                    }
                    &__week{
                        margin-bottom: 20px;
                        &--numberWeek{
                            font-weight: 700;
                            font-size: 40px;
                            line-height: 40px;
                            color: $white;
                            @include mq(mob){
                                font-size: 24px;
                                line-height: 24px;
                            }
                        }
                        &--rangeWeek{
                            font-family: 'Anton';
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 120%;
                            color: $white;
                            @include mq(mob){
                                font-size: 16px;
                            }
                        }
                    }
                    &__ranking{
                        &--top{
                            width: 100%;
                            height: 50px;
                            padding: 0 20px;
                            // @include flex(flex, space-between, center, center);
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                            gap: 20px;
                            background: rgba(0, 0, 0, 0.5);
                            border-radius: 10px;
                            .position{
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 24px;
                                color: $white;
                                width: 120px;
                                @include mq(mob){
                                    font-size: 14px;
                                    line-height: 14px;
                                    width: auto;
                                }
                            }
                            .participant{
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 24px;
                                color: $white;
                                width: 70%;
                                @include mq(mob){
                                    font-size: 14px;
                                    line-height: 14px;
                                    width: 40%;
                                }
                            }
                            .score{
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 24px;
                                color: $white;
                                width: 150px; 
                                @include mq(mob){                   
                                    font-size: 14px;
                                    line-height: 14px;
                                    width: auto; 
                                }
                            }
                        }
                        &--body{
                            width: 100%;
                            height: 65px;
                            padding-right: 20px;
                            // @include flex(flex, space-between, center, center);
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: nowrap;
                            gap: 10px;
                            background: $white;
                            border-radius: 10px;
                            margin: 15px 0;
                            // display: none;
                            @include mq(mob, min) {
                                gap: 20px;
                            }
                            &.showS{
                                display: flex;
                            }
                            .position{
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 24px;
                                color: $blueP;
                                width: 130px;
                                text-align: center;
                                background: rgba(0, 93, 166, 0.1);
                                height: 100%;
                                padding-left: 20px;
                                @include flex;
                                @include mq(mob){
                                    width: 60px;
                                    padding-left: 0;
                                    font-size: 18px;
                                    line-height: 18px;
                                }
                            }
                            .participant{
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 24px;
                                color: $blueP;
                                width: 67%;
                                padding-left: 20px;
                                @include mq(mob){
                                    padding-left: 0;
                                    width: 45%;
                                    font-size: 18px;
                                    line-height: 18px;
                                }
                            }
                            .score{
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 24px;
                                color: $blueP;
                                width: 150px;
                                text-align: center;
                                @include mq(mob){
                                    font-size: 18px;
                                    line-height: 18px;
                                    width: auto;
                                }
                            }
                            &.orange{
                                background: #FEC304;
                                .position, .participant, .score{
                                    color: $white;
                                }
                            }
                        }
                    }
                    .boxPagination{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        &--more{
                            display: inline-block;
                            font-family: 'Anton';
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 120%;
                            color: $yellowP;
                            text-transform: uppercase;
                            margin-top: 30px;
                            text-align: center;
                            text-decoration: underline;
                            cursor: pointer;
                            // display: none;
                            &.disabledBtn{
                                display: none;
                            }
                        }
                    }
                }
                &--play{
                    margin: 40px auto 25px;
                }
            }
        }
    }
}