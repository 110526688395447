.modal{
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.65);
    // background-color: rgba(255, 40, 40, 0.28);
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 99;
    &__container{
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		padding: 30px;
		text-align: center;
		border-radius: 10px;
		padding-top: 50px;
		width: 30vw;
        // height: 30vh;
        background: linear-gradient(180deg, #01B6ED 36.38%, #1166AF 100%);
        border: 2px solid $yellowP;
        border-radius: 12px;
        justify-content: center;
        &--wall{
            mix-blend-mode: multiply;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
        }
		.closePopup{
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
            z-index: 2;
		}
        &--tit{
            font-size: 30px;
            line-height: 30px;
            color: $yellowP;
            z-index: 2;
            letter-spacing: 1px;
            position: relative;
            text-transform: uppercase;
            @include mq(tab,min) {
                font-size: 50px;
                line-height: 70px;
            }
        }
        &--desc{
            font-size: 20px;
            line-height: 150%;
            color: $white;
            z-index: 2;
            letter-spacing: 1px;
            position: relative;
            padding: 0 20px;
            margin-top: -20px;
            margin-bottom: 10px;
            text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
            font-family: 'Iron-Brine';
            @include mq(tab,min) {
                font-size: 32px;
                line-height: 40px;
            }
            @include mq(mob){
                margin-top: -3px;
            }
            &.yellow{
                color: $yellowP;
                margin-top: 15px;
            }
        }
        &--title{
            font-size: 20px;
            line-height: 150%;
            color: $white;
            z-index: 2;
            text-transform: uppercase;
            letter-spacing: 1px;
            position: relative;
        }
        &--btns{
            z-index: 2;
            width: 100%;
            @include flex(flex, space-between, center);
            flex-wrap: nowrap;
            margin-top: 30px;
            .btn{
                width: 190px;
                // height: 50px;
                font-size: 16px;
                // padding: 10px 0;
            }
            .divLogout{
                width: 190px;
            }
        }
		@include mq(767){
			width: 320px;
			// height: 300px;
			padding-left: 0;
			padding-right: 0;
		}
	}
    &.activePopup{
        display: flex;
    }
    //MODAL COMMERCIAL
    &.modalCommercial{
        .modal__container{
            background: transparent;
            border: none;
            width: 65vw;
            height: 60vh;
            .closePopup{
                top: 10px;
                right: 30px;
            }
            .boxIframe{
                width: 100%;
                height: 100%;
                &__iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &.modaliniciahome{
        .modal__container{
            width: 100%;
            max-width: 720px;
            // height: auto;
            height: auto;
            gap: 20px;
            padding: 30px 20px;
            // width: 40vw;
            // height: 30vh;
            // min-height: fit-content;
            @include mq(mobS2,min){
                padding: 60px 30px;
                height: auto;
            }
            @include mq(mob){
                // width: 90vw;
                // height: 60vh;
            }
            &--desc{
                margin-top: 0;
                font-size: 20px;
                line-height: 30px;
                padding: 0;
                font-family: 'Iron-Brine';
                width: 100%;
                // max-width: 585px;
                white-space: pre-line;
                // display: grid;
                @include mq(tab, min) {
                    font-size: 32px;
                    line-height: 40px;
                }
                a{
                    font-family: inherit;
                    font-size: inherit;
                    line-height: inherit;
                }
            }
            .boxBtns{
                @include flex(flex, space-evenly, center, center);
                width: 70%;
                z-index: +2;
                margin-bottom: 10px;
                button{
                    width: 150px;
                    @include mq(mobS){
                        // height: 45px;
                        font-size: 18px;
                        box-shadow: 0px 4px 0px #aca003;
                    }
                }
                div{
                    display: none;
                }
                @include mq(mob){
                    height: 200px;
                    flex-direction: column;
                }
            }
        }
        a{
            font-size: 32px;
            color:#fff;
            text-decoration: underline;
            @include mq(mob){
                font-size: 20px;
            }
        }
        .btninicio01{
            @include mq(mob){
                width: 80%;
            }
        }
    }
    &.modalInformation{
        // display: flex;
        .modal__container{
            // background-image: url('/../img/bkWall.jpg');
            // background-attachment: fixed;
            // background-color: #0062B2;
            background-blend-mode: multiply;
            background: linear-gradient(180deg, #0062B2 7.42%, #01B6ED 75.28%), url('/../img/bkWall.jpg');
            background-size: cover;
            width: 100%;
            max-width: 900px;
            height: 100%;
            gap: 20px;
            padding: 20px;
            overflow: scroll;
            display: block;
            @include mq(mobS2,min){
                height: auto;
            }
            @include mq(mob,min){
                overflow: initial;
                padding: 50px;
            }
            @include mq(tab,min){
                padding: 30px 30px 30px 50px;
            }
            @include mq(deskS,min){
                padding: 60px 30px 60px 50px;
            }
            .title {
                margin-bottom: 20px;
            }
            &--desc{
                text-align: left;
                font-size: 16px;
                line-height: 1;
                margin-top: 0;
                padding: 0;
                font-family: 'Iron-Brine';
                width: 100%;
                @include mq(mob, min) {
                    font-size: 20px;
                }
                @include mq(tab, min) {
                    font-size: 16px;
                }
                @include mq(deskS, min) {
                    font-size: 20px;
                }
                a, p{
                    font-family: inherit;
                    font-size: inherit;
                    line-height: inherit;
                }
                li {
                    list-style: none;
                    margin-bottom: 20px;
                    @include mq(mob,min){
                        list-style: initial;
                    }
                    &:last-of-type {
                        margin-bottom: 0
                    }
                }
            }
            .boxBtns{
                margin-top: 20px;
                // @include flex(flex, space-evenly, center, center);
                // width: 70%;
                // z-index: +2;
                // margin-bottom: 10px;
                button{
                    width: 180px;
                    position: initial;
                    margin: 0 auto;
                    @include mq(mobS){
                        // height: 45px;
                        font-size: 18px;
                        box-shadow: 0px 4px 0px #aca003;
                    }
                }
                div{
                    display: none;
                }
                @include mq(mob){
                    // height: 200px;
                    // flex-direction: column;
                }
            }
        }
        a{
            font-size: 32px;
            color:#fff;
            text-decoration: underline;
            @include mq(mob){
                font-size: 20px;
            }
        }
        .btninicio01{
            @include mq(mob){
                width: 80%;
            }
        }
    }
    &.modalrappi{
        .modal__container{
            width: 100%;
            max-width: 720px;
            height: auto;
            padding: 60px 10px;
            
            // width: 40vw;
            // height: 30vh;
            gap: 30px;
            @include mq(mob,min){
                padding: 60px 30px;
                // width: 90vw;
                // height: 50vh;
            }
            &--desc{
                font-family: 'Iron-Brine';
                font-size: 20px;
                line-height: 30px;
                // width: 80%;
                padding: 0;
                margin: 0;
                @include mq(tab, min) {
                    font-size: 32px;
                    line-height: 40px;
                }
                a{
                    font-size: inherit;
                    line-height: inherit;
                }
            }
            .botonrappi{
                z-index: +2;
            }
        }
        .botonrappi{}
    }
    &.modalAR{
        .modal__container{
            background: transparent;
            border: none;
            width: 65vw;
            height: 65vh;
            @include mq(mob){
                width: 85vw;
            }
            .closePopup{
                top: 75px;
                right: 50px;
                @include mq(mob){
                    top: 32px;
                    right: -10px;
                }
            }
            .boxMap{
                width: 100%;
                height: 100%;
                &__top{
                    width: 100%;
                    height: 15%;
                    background: #004A98;
                    position: relative;
                    @include flex;
                    border-radius: 34px 34px 0 0;
                    p{
                        font-size: 24px;
                        line-height: 120%;
                        color: $white;
                        text-decoration: underline;
                        font-family: 'Anton';
                        padding: 0 15px;
                    }
                }
                &__body{
                    width: 100%;
                    height: 85%;
                    background: $white;
                    border-radius: 0 0 34px 34px;
                    position: relative;
                    #map{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 0 0 34px 34px;
                    }
                }
            }
        }
    }
    &.modalSnack{
        .modal__container{
            width: 40vw;
            height: 40vh;
            &--btns{
                    justify-content: center;
            }
        }
    }
    &.modalRedemption{
        .modal__container{
            width: 35vw;
            height: 40vh;
            @include mq(mob){
                width: 80vw;
            }
            @include mq(mob){
                width: 85vw;
                height: 54vh;
            }
            &--desc{
                font-family: 'Iron-Brine';
                font-size: 32px;
                line-height: 40px;
            }
        }
    }
    &.modalLogout {
        .modal__container{
            // width: 36vw;
            // height: 65vh;
            width: 100%;
            max-width: 670px;
            padding: 30px;
            &--desc{
                font-family: 'Iron-Brine';
                font-size: 32px;
                line-height: 40px;
                margin-top: 0;
            }
            .modal__container--btns{
                width: auto;
                flex-direction: column;
                @include mq(mobS2,min) {
                    flex-direction: row;
                }
                a {
                    margin-bottom: 30px;
                    @include mq(mobS2,min) {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                }
            }
        }
    }
    &.modalRedemptionForm {
        .modal__container{
            width: 50vw;
            height: 40vh;
            &--desc{
                font-family: 'Iron-Brine';
                font-size: 32px;
                line-height: 40px;
            }
            .btn{
                margin-top: 20px;
            }
        }
    }
    &.modalmezcla {
        .modal__container{
            // width: 36vw;
            // height: 65vh;
            width: 100%;
            max-width: 670px;
            padding: 30px;
            height: auto;
            &--desc{
                font-family: 'Iron-Brine';
                // font-size: 32px;
                // line-height: 40px;
                margin-top: 0;
            }
            .btn{
                margin-top: 20px;
            }
        }
    }
    &.modalmezclacomplete {
        .modal__container{
            // width: 36vw;
            width: 100%;
            max-width: 670px;
            // height: 65vh;
            height: auto;
            // margin: 6% auto;
            padding: 30px;
            &--desc{
                font-family: 'Iron-Brine';
                font-size: 32px;
                line-height: 40px;
            }
            .btn{
                margin-top: 20px;
                &--cancel{
                    background-color: #fff;
                    box-shadow: 0px 10px 0px #8f8f8f;
                }
            }
        }
        &.show{
            display: flex;
            z-index: 99;
        }
    }
    &.modalmezclaIsLoggedIn {
        .modal__container {
            // width: auto;
            width: 100%;
            max-width: 750px;
            padding: 30px;
            &--desc {
                margin-top: 0;
                margin-bottom: 20px;
                @include mq(tab,min) {
                    margin-bottom: 40px;
                    font-size: 32px;
                }
            }
        }
    }
    &.videoModal{
        .modal__container{
            width: 59vw;
            height: 70vh;            
            background: none;
            border: none;
            @include mq(tab){
                width: 624px;
                height: 441px;
            }
            @include mq(mobS2){
                width: 100%;
                height: 350px;
            }
            .boxIframe{
                width: 100%;
                height: 100%;
                iframe{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &.modalVideoWallet {
        .modal__container {
            gap: 30px;
            width: 100%;
            max-width: 750px;
            padding-left: 10px;
            padding-right: 10px;
            .title {
                color: #FFF200;
                font-family: 'Alfarn';
                z-index: 1;
                font-size: 28px;
                @include mq(tab,min) {
                    font-size: 40px;
                }
            }
            .code {
                z-index: 1;
                font-size: 20px;
                color: $white;
                font-family: 'Alfarn';
                word-break: break-word;
                @include mq(tab,min) {
                    font-size: 24px;
                }
            }
            &--desc {
                z-index: 1;
                font-family: 'Iron-Brine';
                font-size: 16px;
                line-height: 18px;
                @include mq(tab,min) {
                    line-height: 26px;
                }
            }
            .boxBtns {
                @include mq(tab, min) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                }
                button {
                    z-index: 1;
                    width: 190px;
                    &.btnInicioSi {
                        margin-bottom: 35px;
                        @include mq(tab, min) {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}