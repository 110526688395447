.containerMezcla {
    // background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 60px);
    padding-bottom: 50px !important;
    position: relative;
    @include mq(tab,min) {
        // padding-bottom: 50px;
    }
    &--wall {
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        @include mq(mob){
            object-fit: cover;
            object-position: top;
        }
    }
    &--imgLeft {
        opacity: 0.8;
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: 20px;
        left: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob) {
            left: 0;
            display:none;
        }
    }
    &--imgRight {
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        right: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob) {
            right: 0;
            display:none;
        }
    }
    .iconsAwards {
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab) {
            display: none;
        }
    }
    &--iconPap {
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab) {
            display: none;
        }
    }
    &--iconPlat {
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab) {
            display: none;
        }
    }
    &--iconChi {
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab) {
            display: none;
        }
    }
    &__content {
        width: 100%;
        position: relative;
        .bkTitle {
            &--title {
                color: $blueP;
            }
        }
        &--desc {
            color: $blueP;
            font-family: 'Iron-Brine';
            font-size: 32px;
            line-height: 40px;
            width: 60%;
            margin: 0 auto;
            text-align: center;
            z-index: +2;
            position: relative;
            &.white{
                color: $white;
            }
            @include mq(mob){
                width: 100%;
                font-size: 28px;
                padding-bottom: 20px;
                line-height: 34px;
            }
            &.mobile {
                @include mq(mob,min) {
                    display: none;
                }
            }
            &.desktop {
                display: none;
                @include mq(mob,min) {
                    display: block;
                }

            }
        }
        .boxMezcla {
            width: 100%;
            max-width: 400px;
            margin: 30px auto 0;
            position: relative;
            // @include flex(flex, space-around, center, center);
            @include mq(tab,min){
                width: 80%;
                max-width: none;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 50px;
                margin: 30px auto;
            }
            @include mq(deskS,min){
                width: 80%;
                max-width: none;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 50px;
                margin: 50px auto;
            }
            .arrowBig {
                display: none;
                position: absolute;
                top: 35%;
                // left: 46%;
                left: -50px;
                transform: translate(-50%, -50%);
                @include mq(tab,min){
                    display: block;
                }
                .hover {
                    display: none;
                }
                // img {
                //     animation-duration: 3s;
                //     animation-iteration-count: infinite;
                //     animation-direction: alternate;
                // }
                // @keyframes moviRotateMezcla {
                //     0% {
                //         transform: rotate(-5deg)
                //     }
                //     50% {
                //         transform: rotate(20deg);
                //     }
                //     100% {
                //         transform: rotate(-5deg)
                //     }
                // }
            }
            &--left {
                // width: 48%;
                text-align: center;
                position: relative;
                @include mq(tab){
                    width:100%;
                }
                .thebolsa {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // height: 60vh;
                    @include mq(mobS){
                        // height: 50vh;
                    }
                    @include mq(mobS2){
                        // height: 45vh;
                    }
                }
                .maskBlue {
                    width: 150%;
                    height: 150%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .bolsa {
                    // top: 0;
                    // left: 0;
                    // position: absolute;
                    z-index: 99;
                    // @include flex;
                    width: 100%;
                    height: 100%;
                    // bottom: 0;
                    // margin: auto;
                    position: relative;
                    left: -30px;
                    @include mq(mobS3,min){
                        left: -20px;
                    }
                    @include mq(mobS2,min){
                        position: initial;
                        left: 0;
                    }
                    img{
                        width: 380px;
                        @include mq(tab,min) {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .papas {
                    // left: 50%;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // height: 100%;
                    // transform: translate(-50%, -50%);
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     width: 100%;
                    //     height: 100%;
                    //     object-fit: contain;
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .chicharron {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                    
                }
                .natuchips {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .cheesetris {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .cheetos {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .doritos {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .onduladas {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .tocineta {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .arepitas {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                &--btn{
                    position: absolute;
                    bottom: 20px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    height: 50px;
                    width: 250px;
                    display: none;
                    z-index: +99;
                    &.activeBtn{
                        display: flex;
                    }
                }
                .manimoto {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .chokis {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .crispetas {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .choclitos {
                    // top: 0px;
                    // left: 0;
                    // position: absolute;
                    // z-index: 10;
                    // width: 100%;
                    // display: none;
                    // &.show {
                    //     display: block;
                    // }
                    // @include mq(1600, min){
                    //     top: 60px;
                    // }
                    // @include mq(mob){
                    //     top: 0;
                    // }
                    // img{
                    //     @include mq(mob){
                    //         width: 100%;
                    //     }
                    // }
                }
                .iconBolsa {
                    top: 0;
                    left: 0;
                    position: absolute;
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    transition: 1s all ease-in-out;
                    transform: scale(0) translate(-50%, -50%);
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                    &.show {
                        display: block;
                        transform: scale(1) translate(-50%, -50%);
                        transition: 1s all ease-in-out;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
            &--right {
                // width: 48%;
                min-height: 538px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                @include mq(tab){
                    min-height: auto;
                }
                &.rightListado{
                    @include mq(tab){
                        width: 80%;
                        min-height: auto;
                    }
                }
                &--flavors {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 20px;
                    &--single {
                        text-align: center;
                        transition: .5s all ease-in-out;
                        cursor: grab;
                        &:hover {
                            transition: .5s all ease-in-out;
                            transform: scale(1.2);
                            &~ .arrowBig {
                                // transform: translate(-50%, -50%) scale(1.2);
                                // img {
                                //     animation-name: moviRotateMezcla;
                                // }
                                .noHover {
                                    display: none;
                                }
                                .hover {
                                    display: block;
                                }
                            }
                        }
                        &.ingSelected {
                            position: relative;
                            //opacity: 0.4 !important;
                            &::after{
                                content: "✓";
                                position: absolute;
                                width: 40px;
                                height: 40px;
                                top: 0;
                                right: calc(50% - 71px);
                                background-color:#72BF44;
                                color: #fff;
                                font-size: 24px;
                                font-weight: 800;
                                border-radius: 50%;
                                line-height: 38px;
                            }
                        }
                    }
                    @include mq(tab){
                        display:none;
                    }
                }
                &__content{
                    width: 100%;
                    min-height: 460px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    align-content: flex-start;
                    flex-direction: column;
                    &--title{
                        font-family: 'Iron-Brine';
                        color: $blueP;
                        text-transform: uppercase;
                        font-size: 28px;
                        line-height: 40px;
                        margin-bottom: 20px;
                    }
                    &--flavor{
                        font-family: 'Iron-Brine';
                        color: $blueP;
                        text-transform: uppercase;
                        font-size: 28px;
                        line-height: 40px;
                    }
                    &--edit{
                        font-family: 'Iron-Brine';
                        color: $blueP;
                        text-transform: uppercase;
                        font-size: 28px;
                        line-height: 40px;
                        margin-top: 20px;
                        text-decoration: underline;
                    }
                }
            }
            .nextmezcla01{
                display:none;
                text-align: center;
                &.show{
                    display:block;
                }
            }
            .ingredientmobile{
                width: 100%;
                display:none;
                position: relative;
                @include mq(tab){
                    display:block;
                }
                .slideflavors{
                    .swiper-slide {
                        padding-top: 30px;
                    }
                    &__navigation{
                        .swiper-button-prev{
                            color: #FFF200;
                            border: 2px solid #FFF200;
                            border-radius: 10px;
                            width: 40px;
                            height: 40px;
                            background: #FFF200;
                            box-shadow: 0px 10px 0px #aca003;
                            left: 20px;
                            &::after{
                                font-size: 18px;
                                font-weight: 900;
                                margin-right: 4px;
                                color: #005DA6;
                            }
                        }
                        .swiper-button-next{
                            color: #FFF200;
                            border: 2px solid #FFF200;
                            border-radius: 10px;
                            width: 40px;
                            height: 40px;
                            background: #FFF200;
                            box-shadow: 0px 10px 0px #aca003;
                            right: 20px;
                            &::after{
                                font-size: 18px;
                                font-weight: 900;
                                margin-left: 4px;
                                color: #005DA6;
                            }
                        }
                    }
                }
            }
        }
    }
}

