.containeruserArea{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(180deg, #01B6ED 36.38%, #1166AF 100%);
    position: relative;
    overflow-x: hidden;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        object-fit: cover;
    }
    .iconsuserArea{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        &--titleBox{
            @include flex;
            padding: 40px 0 20px;
            @include mq(tab){
                padding: 40px 0;
            }
        }
        &--title, &--titleAward{
            font-size: 32px;
            line-height: 37px;
            color: $white;
            text-align: center;
            position: relative;
            text-transform: uppercase;
            z-index: 1;
            display: grid;
            @include mq(mobS, min) {
                font-size: 46px;
                line-height: 52px;
                display: initial;
            }
            @include mq(lapS, min) {
                font-size: 60px;
                line-height: 60px;
            }



            &::before{
                content: '';
                position: absolute;
                background: url('/img/iconMancha.png');
                left: -60px;
                top: 0px;
                width: 120px;
                height: 120px;
                background-size: contain;
                background-repeat: no-repeat;
                z-index: -1;
            }
            &::after{
                content: '';
                position: absolute;
                background: url('/img/marcorightSuper.png'); 
                width: 150px;
                height: 120px;
                background-size: contain;
                background-repeat: no-repeat;
                top: -20px;
                right: -30px;             
            }
        }
        &--copy{
            font-weight: 400;
            font-size: 22px;
            line-height: 30px;
            color: $white;
            // width: 60%;
            margin: 0 auto;
            text-align: center;
            font-family: 'Iron-Brine';
            // white-space: pre-line;
            width: 100%;
            max-width: 675px;
            @include mq(mob){
                font-size: 18px;
                line-height: 150%;
                width: 100%;
            }
        }
        &--points{
            @include flex(flex, space-between, flex-start, center);
            // width: 50%;
            width: 100%;
            max-width: 700px;
            margin: 50px auto 30px;
            @include mq(mob){
                width: 100%;
                margin-top: 50px;
                justify-content: center;
                flex-direction: column-reverse
            }
            .boxMoney{
                @include flex;
                flex-direction: column;
                &--content{
                    width: 230px;
                    height: 145px;
                    position: relative;
                    margin-bottom: 60px;
                    &--bk{
                        position: absolute;
                        left: -15px;
                        top: -45px;
                    }
                    &--points{
                        z-index: 0;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-content: center;
                        height: 50%;
                        margin-top: 14px;
                        img{
                        }
                        .points{
                            font-size: 43px;
                            line-height: 100%;
                            color: #0056B6;
                            margin-left: 20px;
                            font-family: 'Iron-Brine';
                        }
                    }
                    &--copy{
                        font-size: 24px;
                        line-height: 36px;
                        color: #0056B6;
                        z-index: 0;
                        position: relative;
                        height: 25%;
                        text-align: center;
                        text-transform: uppercase;
                        @include flex;
                        font-family: 'Iron-Brine';
                        &::before{
                            content: '';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            width: 75%;
                            height: 2px;
                            background: #0056B6;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .boxPosition{
                @include flex;
                flex-direction: column;
                @include mq(mob){
                    margin-top: 60px;
                    order: -1;
                }        
                &--content{
                    width: 230px;
                    height: 145px;
                    position: relative;
                    margin-bottom: 60px;
                    &--bk{
                        position: absolute;
                        left: -15px;
                        top: -45px;
                    }
                    &--points{
                        z-index: 0;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-content: center;
                        height: 50%;
                        margin-top: 14px;
                        .points{
                            font-family: 'Anton';
                            font-size: 43px;
                            line-height: 100%;
                            color: #0056B6;
                            font-family: 'Iron-Brine';
                        }
                    }
                    &--copy{
                        font-size: 24px;
                        line-height: 24px;
                        color: #0056B6;
                        z-index: 0;
                        position: relative;
                        height: 30%;
                        text-align: center;
                        text-transform: uppercase;
                        @include flex;
                        font-family: 'Iron-Brine';
                        &::before{
                            content: '';
                            position: absolute;
                            top: -10px;
                            left: 0;
                            width: 75%;
                            height: 2px;
                            background: #0056B6;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
        .boxPrize{
            margin-top: 100px;
            @include mq(mob){
                width: 70%;
                .boxUser--name{
                    font-size: 24px;
                }
            }
        }
        .boxaward{
            width: 90%;
            max-width: 990px;
            margin: 70px auto;
            @include mq(mob){
                width: 90%;
            }
            &__content{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 40px;
                @include mq(mob){
                    margin-bottom: 45px;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 20px;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                &--single{
                    width: 100%;
                    height: 113px;
                    background-color: $white;
                    border-radius: 10px;
                    @include flex(flex, flex-start, center);
                    position: relative;
                    flex-flow: unset;
                    @include mq(mob){
                        flex-wrap: nowrap;
                    }
                    &--money{
                        position: absolute;
                        top: -30px;
                        left: -30px;
                        width: 60px;
                        height: 60px;
                        img{
                            width: 100%;
                        }
                        p{
                            text-transform: uppercase;
                            font-size: 9.24622px;
                            line-height: 11px;
                            color: #893A1B;
                            text-align: center;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            right: 0;
                            @include flex;
                            height: 56px;
                        }
                    }
                    &--img{
                        height: 100%;
                        @include mq(mob){
                            height: 100%;
                            width: 40%;
                            object-fit: contain;
                        }
                    }
                    &--desc{
                        padding: 5px 10px;
                        // margin-left: 20px;
                        @include mq(mob){
                            // margin-left: 10px;
                            padding-right: 10px;
                        }
                        .text1{
                            font-size: 24px;
                            line-height: 150%;
                            color: #114C96;
                            font-family: 'Anton';
                            @include mq(mob){
                                font-size: 22px;
                            }
                        }
                        .text2{
                            font-size: 14px;
                            line-height: 150%;
                            color: #04A5DF;
                            font-family: 'Anton';
                            @include mq(mob){
                                font-size: 13px;
                            }
                        }
                        .text3{
                            font-size: 14px;
                            line-height: 150%;
                            color: #04A5DF;
                            font-family: 'Anton';
                            @include mq(mob){
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            &__empty {
                &:only-child {
                    @include mq(mob,min) {
                        grid-column: 1 / 3;
                    }
                }
            }
        }
    }
}