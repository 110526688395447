.btn{
    font-family: 'Alfarn';
    background: $yellowP;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    font-size: 22px;
    color: $blueP;
    text-transform: uppercase;
    box-sizing: border-box;
    border: none;
    width: 250px;
    height: 50px;
    line-height: 55px;
    display: block;
    text-align: center;
    // padding: 10px 40px;
    // line-height: 36px;
    // @include flex;
    cursor: pointer;
    box-shadow: 0px 10px 0px #ACA003;
    @include mq(mob,min) {
        width: 300px;
        height: 70px;
        line-height: 75px;
    }
}