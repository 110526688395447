.bideoBilletera {
    width: 90%;
    // margin-top: -100px;
    &__body {
        width: 100%;
        min-height: 40vh;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq(mobS2,min) {
            min-height: 55vh;
        }
        @include mq(mob,min) {
            min-height: 85vh;
        }
        .content {
            z-index: 1;
            width: 90%;
            position: relative;
            top: -15px;
            cursor: pointer;
            @include mq(mob,min) {
                top: -45px;
            }
            &__img {
                width: 90%;
                margin: 0 auto;
                border-radius: 13px;
                overflow: hidden;
                @include mq(mob,min) {
                    width: 75%;
                }
                img {
                    width: 100%;
                    height: 100%;
                    // object-fit: contain;
                }
            }
            &__play {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include mq(tab,min) {
                    width: 70px;
                    height: 70px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &__bk {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        img {
            width: 115%;
            height: 100%;
            position: relative;
            left: -5%;
        }
    }
}