.footer{
    width: 100%;
    height: 200px;
    z-index: 1;
    position: relative;
    background: $black;
    padding: 20px 0;
    @include mq(mob){
        height: auto;
    }
    &--bk{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: -98px;
        object-fit: cover;
        object-position: top;
        @include mq(mob){
            object-position: left;
            height: 70%;
            top: -60px;
        }
    }
    &__content{
        width: 100%;
        height: 90%;
        @include flex(flex, space-around, center, center);
        @include mq(mob){
            flex-direction: column;
        }
        &--logo{
            width: auto;
            height: 100%;
            @include mq(mob){
                height: 120px;
            }
            img{
                width: 100%;
                height: 80%;
                object-fit: contain;
            }
        }    
        &--nav{
            // width: 40%;
            width: auto;
            height: 100%;
            order: -1;
            @include mq(mob){
                width: 100%;
                margin-bottom: 30px;
                order: 0;
            }
            ul{
                width: 100%;
                height: 100%;
                @include flex();
                flex-direction: column;
                gap: 18px;
                li {
                    list-style: none;
                }
                a{
                    color: $white;
                    font-family: 'Alfarn';
                    font-size: 14px;
                    line-height: 20px;
                    // letter-spacing: 0.05em;
                    text-transform: uppercase;
                    font-weight: 700;
                    display: grid;
                    text-align: center;
                    @include mq(mob, min) {
                        display: initial;
                    }
                }
                @include mq(mob){
                    flex-direction: column;
                    gap: 30px;
                    a{
                        margin: 10px 0;
                    }
                }
            }
        }
        &--social{
            @include flex(flex, center, center, center, nowrap);
            @include mq(mob){
                order: 1;
            }
            &--link{
                font-size: 20px;
                line-height: 32px;
                padding: 5px 20px;
                border-radius: 200px;
                border: 2px solid $yellowP;
                color: $yellowP;
                text-transform: uppercase;
                @include flex;
                img{
                    order: -1;
                    margin-right: 5px;
                }
            }
            .boxSocial{
                @include flex(flex, center, center, center, nowrap);
                a {
                    &:last-of-type {
                        img {
                            margin-right: 0;
                        }
                    }
                }
                img{
                    object-fit: contain;
                    width: 28px;
                    height: 28px;
                    margin-right: 25px;
                }
            }
        }
        &--legal{
            width: 100%;
            text-align: center;
            font-size: 10.4274px;
            line-height: 15px;
            font-family: 'Montserrat';
            color: $white;
            @include mq(mob){
                width: 50%;
                margin-bottom: 20px;
            }
        }
    }
}