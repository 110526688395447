.containerAlbum{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    &--imgLeft{
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        left: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob){
            left: 0;
        }
    }
    &--imgRight{
        position: absolute;
        width: auto;
        // max-width: 300px;
        height: 105%;
        top: -60px;
        right: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(mob){
            right: 0;
        }
    }
    .iconsAlbum{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        &--title{
            font-size: 24px;
            line-height: 150%;
            text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
            color: $white;
            width: 750px;
            text-align: center;
            margin: 40px auto 80px;
            span{
                color: $yellowP;
            }
            @include mq(tab){
                width: 100%;
                font-size: 16px;
                margin: 20px 0 40px;
            }
        }
        .boxProgress{
            text-align: center;
            &--label{
                color: $white;
                margin-right: 10px;
            }
            &--progress{
                border-radius: 7px; 
                width: 40%;
                height: 15px;
                margin: 0 auto;
                @include mq(tab){
                    width: 70%;
                }
                &::-webkit-progress-bar {
                    background-color: $yellowP;
                    border-radius: 7px;
                }
                &::-webkit-progress-value {
                    background-color: #F93105;
                    border-radius: 7px;
                }
                &::-moz-progress-bar {
                    // background-color: purple;
                }
            }
            &--labelBonus{
                color: $yellowP;
                margin-left: 10px;
            }
        }
        .boxcodesSnacks{
            margin-top: 40px;
            @include mq(tab){
                padding-bottom: 40px;
            }
            &__single{
                max-width: 230px;
                position: relative;
                @include mq(tab){
                    margin: 0 auto;
                }
                &--snack{
                    background: $blueLight; 
                    border: 3px solid $white;
                    border-radius: 15px;
                    padding: 25px;
                    @include flex;
                    flex-direction: column;
                    position: relative;
                    .formcodeSnack{
                        position: relative;
                        &--input{
                            background: $black;
                            border: 2px solid $white;
                            color: $yellowP;
                            font-size: 24px;
                            border-radius: 16px;
                            width: 150px;
                            text-align: center;
                            font-family: 'Anton';
                            font-weight: 400;
                            &:focus-visible{
                                outline: none;
                            }
                            &::-webkit-inner-spin-button {
                                opacity: 0;
                                -webkit-appearance: none;
                            }
                        }
                        .msninputSnack{
                            color: #F93105;
                            font-family: 'Montserrat';
                            font-weight: 600;
                            letter-spacing: 2px;
                            font-size: 16px;
                            text-align: center;
                            position: absolute;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            &.hide{
                                display: none;
                            }
                        }
                    }
                    img{
                        width: 150px;
                        margin-top: 40px;
                    }
                    &--mask{
                        display: none;
                        background: rgba(0, 0, 0, 0.5);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 16px;
                    }

                }
                &--coins{
                    width: 170px;
                    height: 105px;
                    position: relative;
                    margin: 20px auto 30px;
                    &--bk{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .boxCoin{
                        z-index: 0;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-content: center;
                        height: 60%;
                        img{
                            width: 40px;
                            height: 40px;
                        }
                        .points{
                            font-size: 40px;
                            line-height: 72px;
                            color: $yellowP;
                            margin-left: 20px;
                        }
                    }
                    &--copy{
                        font-size: 24px;
                        line-height: 36px;
                        color: $white;
                        z-index: 0;
                        position: relative;
                        height: 40%;
                        text-align: center;
                        text-transform: uppercase;
                        @include flex;
                    }
                }
                &--lock{
                    display: none;
                    position: absolute;
                    top: 45%;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 80px !important;
                    margin-top: 0 !important;
                }
                &.disabled{
                    .boxcodesSnacks__single--snack--mask{
                        display: block;
                    }
                    .boxcodesSnacks__single--lock{
                        display: block;
                    }
                    .boxcodesSnacks__single--snack{
                        border: 3px solid rgba(255, 255, 255, 0.644);
                    }
                }
            }
            .swiper-pagination{
                &-bullet{
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    background-color: $white;
                    &-active{
                        
                    }
                }
            }
        }
        .btn{
            margin: 20px auto 0;
        }
    }
}