.header{
    width: 100%;
    background: linear-gradient(180deg, #000000 -21.41%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    background-position: center;
    height: 100px;
    z-index: 9;
    position: relative;
    &--bk{
        width: 100%;
        height: 100px;
        position: absolute;
        left: 0;
        top: 0;
    }
    @include mq(lapS){
        height: 80px;
        &.active{
            .header__content--nav{
                opacity: 1;
                pointer-events: all;
                width: 100%;
                position: fixed;
                height: 100%;
                top: 0px;
                left:0;
                background: linear-gradient(0deg, #052446 7.55%, #034EA2 100%);
                &--bk1, &--bk2{
                    display: block;
                }
                ul{
                    flex-direction: column;
                    justify-content: center;
                    a{
                        margin-bottom: 10px;
                    }
                }
            }
            .boxSocial{
                opacity: 1;
                position: fixed;
                bottom: 60px;
                left: 0;
                right: 0;
                margin-left: 0;
                top: unset;
            }
        }
    }
    &__content{
        width: 100%;
        height: 100%;
        max-width: 95%;
        @include flex(flex, space-between, center, center);
        @include mq(mob){
            // max-width: 100%;
            height: 80px;
        }
        @include mq(mob,min){
            padding: 15px 20px 0;
        }
        &--logo{
            width: auto;
            height: 100%;
            z-index: 1;
            img{
                z-index: +2;
                width: 100%;
                height: 95%;
                object-fit: contain;
            }
            @include mq(mob){
                position: absolute;
                left: 0;
                top: 10px;
            }
        }
        &--nav{
            min-width: 800px;
            height: 100%;
            // width: calc(100% - 480px);
            // position: absolute;
            @include mq(lapS){
                opacity: 0;
                pointer-events: none;
                top: -50000px;
                position: absolute;
                transition:  opacity .5s ease-in-out;
                min-width: 100%;
                .containerawardsRedemption {
                    height: auto;
                }
            }
            &--bk{
                display: none;
                position: absolute;
                opacity: 0.5;
                left: 0;
                bottom: 145px;
                width: 100px;
                animation: floatSpace 7s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
                animation-delay: .5s;
            }
            &--bk1{
                display: none;
                position: absolute;
                right: -120px;
                transform: scaleY(-1) rotate(337deg);
                opacity: .5;
                z-index: -1;
                pointer-events: none;
                bottom: -90px;
            }
            &--bk2{
                display: none;
                opacity: 0.5;
                left: -40px;
                top: 85px;
                width: 150px;
                transform: scaleX(-1);
                position: absolute;
                animation: floatSpace 7s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
            }
            ul{
                width: 100%;
                height: 100%;
                @include flex(flex, space-evenly, center, center);
                a{
                    color: $yellowP;
                    font-size: 16px;
                    line-height: 28px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    position: relative;
                    &.active{
                        &::after{
                            content: '';
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translateX(-50%);
                            width: 70%;
                            height: 3px;
                            background-color: $yellowP;
                        }
                    }
                    &:last-of-type{
                        @include mq(lapS,min) {
                            /*display: none;*/
                        }
                    }
                    &.oculto{
                        display: none;
                        @include mq(lapS){
                            display:block;
                        }
                    }
                }
                @include mq(lapS){
                    font-size: 24px;
                    line-height: 36px;

                }
            }
        }
        &--social{
            @include flex(flex, center, center, center, nowrap);
            position: relative;
            .navDropdown{
                position: relative;
                &:hover{
                    .dropdown{
                        display: block;
                    }
                }
            }
            &--link{
                font-size: 20px;
                padding: 10px 40px;
                border-radius: 200px;
                border: 2px solid $yellowP;
                color: $yellowP;
                text-transform: uppercase;
                @include flex;
                p{
                    font-size: 18px;
                }
                img{
                    height: 20px;
                    order: -1;
                    margin-right: 5px;
                    margin-top: -3px;
                }
                @include mq(lapS){
                    display: none;
                }
            }
            .dropdown{
                position: absolute;
                top: 40px;
                background: #FFED02;
                width: 170px;
                left: 0;
                right: 0;
                margin: 0 auto;
                border-radius: 0px 0 20px 20px;
                z-index: +9;
                padding-top: 0px;
                display: none;
                &--single{
                    width: 100%;
                    height: 45px;
                    position: relative;
                    a{
                        width: 100%;
                        height: 100%;
                        @include flex;
                        img{
                            width: 19px;
                            height: 19px;
                            object-fit: contain;
                        }
                        p{
                            font-size: 14px;
                            line-height: 21px;
                            color: $black;
                            margin-left: 20px;
                            font-family: 'Anton';
                        }
                    }
                    &:not(:last-child){
                        &::after{
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background-color: #034EA2;
                        }
                    }
                }
            }
            .boxSocial{
                @include flex(flex, center, center, center, nowrap);
                margin-left: 50px;
                @include mq(mob){
                    opacity: 0;
                    transition: opacity .5s ease-in-out;
                    top: -50000px;
                    position: absolute;
                }
                img{
                    object-fit: contain;
                    width: 28px;
                    height: 28px;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }

        .menuMobile{
            display: none;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            flex-wrap: nowrap;
            position: absolute;
            top: 0;
            right: 0px;
            width: 38px;
            height: 38px;
            margin: auto;
            bottom: 0;
            transition: all .5s ease-in-out;
            transition-delay: .15s;
            z-index: +10;
            @include mq(lapS){
                display: flex;
                // display: block;
                // right: 30px;
                // top: 15px;
            }
            @include mq(mob,min){
                right: 20px;
                top: 16px;
            }
            &--user{
                margin-right: 65px;
                @include flex;
                justify-content: flex-end;
                display: none;
                height: 35px;
                width: 35px;
                @include mq(lapS){
                    display: flex;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &__line{
                display: block;
                width: 30px;
                height: 3px;
                background: $yellowP;
                border-radius: 8px;
                transition: all .3s ease;
                overflow: hidden;
                z-index: +1;
                &:first-of-type{
                    transform: translateY(-7px);
                }
                &:nth-of-type(2){
                    // width: 23px;
                }
                &:last-of-type{
                    transform: translateY(7px);
                }
            }
            &.active{
                .menuMobile{
                    &__line{
                        &:first-of-type{
                            transform: translateY(3px) rotate(-45deg);
                        }
                        &:nth-of-type(2){
                            opacity: 0;
                        }
                        &:last-of-type{
                            transform: translateY(-3px) rotate(45deg);
                            transition-delay: .1s;
                        }
                    }
                }
            }
        }
    }
}
.detodito-container{
    width: 1280px;
    margin: 0 auto;
    /*@include mq(deskS){
        width: 1280px;
    }*/
    @include mq(lapS){
        width: 100%;
        // padding: 0 20px;
    }
    @include mq(mob){
        height: 80px;
    }
}
.testanimate{
    @include mq(mob){
        display: none;
    }
    &.porarriba{
        z-index: 1;
        position: fixed;
        height: 100vh;
        width: 100%;
    }
    .iconPlat{
        position: absolute;
        right: 0;
        width: 150px;
        top: -135px;
        rotate:0deg;
        animation-name:detoditofalldown;
        animation-duration: 18s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: +99;
    }
    .iconChidos{
        position: absolute;
        right: 0;
        width: 90px;
        top: -125px;
        rotate:0deg;
        animation-name:detoditofalldown;
        animation-duration: 28s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: +99;
    }
    .iconPap{
        position: absolute;
        left: 0;
        width: 150px;
        top: -135px;
        rotate:0deg;
        animation-name:detoditofalldownl;
        animation-duration: 25s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: +99;
    }
    .iconChi{
        position: absolute;
        left: 0;
        width: 108px;
        top: -115px;
        rotate:0deg;
        animation-name:detoditofalldownl;
        animation-duration: 10s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        z-index: +99;
    }
    @include mq(mob){
        display: none;
    }
}
@keyframes detoditofalldown {
    0%   {top: -10%; rotate: 50deg; right:0;}
    25%  {top: 25%; rotate: 10deg; right:30px;}
    50%  {top: 50%; rotate: 90deg; right:-10px;}
    100% {top: 110%; rotate: 10deg; right:0;}
  }
  @keyframes detoditofalldownl {
    0%   {top: -10%; rotate: 50deg; right:0;}
    25%  {top: 25%; rotate: 10deg; right:30px;}
    50%  {top: 50%; rotate: 90deg; right:-10px;}
    100% {top: 110%; rotate: 10deg; right:0;}
  }
