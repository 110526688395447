@keyframes floatSpace {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(1px, -20px) rotate(5deg);
    }
    50% {
        transform: translate(0, 0) rotate(0deg);
    }
    75% {
        transform: translate(-1px, -20px) rotate(-5deg);
    }
    100% {
        transform: translate(0, 0) rotate(0deg);
    }
}