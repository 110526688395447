.containerlandingGame{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px !important;
    position: relative;
    @include mq(mob){
       min-height: 100vh;
    }
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        object-fit: cover;
    }
    .iconlGame{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: unset !important;
        top: unset !important;
        left: 0 !important;
        width: 150px;
        bottom: 10% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        .bannerGame{
            width: 100%;
            /*height: 100%;*/
            @include flex(flex, space-between, center, center);
            width: 100%;
            flex-direction: column;
            @include mq(mob){
                max-width: 100%;
                position: relative;
                padding-bottom: 120px;
                height: unset;
            }
            &--left{
                width: 50%;
                @include mq(mob){
                    width: 100%;
                }
                &--content{
                    
                    &--img{
                        width: 349px;
                        margin: 29px auto 15px;
                        display: block;
                        @include mq(mob){
                            margin: 0 auto;
                            display: block;
                            width: 310px;
                        }
                    }
                    p{
                        color:#fff;
                        text-align: center;
                        font-size: 18px;
                        @include mq(mob){
                            padding: 0 20px;
                        }
                    }
                    .btn{
                        text-align: center;
                        margin: 25px auto;
                        box-shadow: 0px 10px 0px #ACA003;
                        font-family: 'Alfarn';
                        @include mq(mob){
                            position: absolute;
                            bottom: 60px;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            width: 250px;
                            height: 55px;
                        }
                    }
                }
            }
            &--right{
                width: 90%;
                position: relative;
                //height: 100%;
                @include mq(desk){
                    height: 100%;
                }
                @include mq(mob){
                    width: 100%;
                }
                img{
                    width: 100%;
                }
                &--points{
                    position: absolute;
                    top: 73px;
                    left: 63px;
                    right: 0;
                    width: 120px;
                    margin: 0 auto;
                    @include mq(mob){
                        top: 60px;
                        left: 50px;
                    }
                    &--img{
                        transform: rotate(8.62deg);
                        width: 100%;
                    }
                    &--copy{
                        font-family: 'Anton';
                        font-size: 37px;
                        line-height: 100%;
                        color: $white;
                        transform: rotate(4.95deg);
                        position: absolute;
                        bottom: 16px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        text-align: center;
                    }
                }
                .bodegon{
                    .desktop{
                        display:block;
                        @include mq(mob){
                            display:none;
                        }
                    }
                    .mobile{
                        display:none;
                        @include mq(mob){
                            display:block;
                            width: 65%;
                            margin: 0 auto;
                        }
                        @include mq(mobS2){
                            width: 85%;
                        }
                        @include mq(mobS3){
                            width: 95%;
                        }
                    }
                }
            }
        }
        .detodito-container{
            @include mq(mob){
                height: 100%;
                padding: 0;
            }
        }
    }
}