// CONTAINER
.container {
	width: 100%;
	position: relative;
	margin-top: -100px;
	&--general {
		overflow: hidden;
		padding-top: 110px;
		padding-bottom: 150px !important;
		z-index: 0;
		position: relative;
		@include mq(tab,min){
			padding-top: 130px;
		}
	}
	&--limit {
		max-width: $widthLimit;
		margin: 0 auto;
		z-index: 0;
		position: relative;
		@include mq(mob){
			max-width: 90%;
		}
	}
	.removeHeight {
		min-height: auto;
	}
}
