.containerconfPass{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    @include mq(mob){
        padding-bottom: 80px;
    }
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    &--imgLeft{
        position: absolute;
        width: auto;
        height: 110%;
        top: -60px;
        left: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(tab){
            display: none;
        }
    }
    &--imgRight{
        position: absolute;
        width: auto;
        height: 110%;
        top: -60px;
        right: -20px;
        object-position: top;
        pointer-events: none;
        @include mq(tab){
            display: none;
        }
    }    
    .iconsconfPass{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        @include flex;
        flex-direction: column;
        position: relative;
        &--copy{
            font-size: 24px;
            line-height: 150%;
            color: #FFFFFF;
            position: relative;
            margin-bottom: 20px;
            font-family: 'Iron-Brine';
            @include mq(tab){
                font-size: 20px;
                line-height: 28px;
            }
            &::after{
                // content: '';
                width: 100%;
                height: 1px;
                background: $white;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
        .formG{
            width: 50%;
            @include mq(mob){
                width: 100%;
            }
            &__content{
                flex-direction: column;
                &--input{
                    align-content: center;
                }
            }
        }
    }
}