#terms-condition {
    text-shadow: 1px 1px 2px #272a31;
    text-align: justify;
    .subTitle {
        color: #fff
    }
    .indexText {
        color: #fff;
    }
    .subIndexText {
        color: #fff;
        padding: 5px 0 15px;
    }
    p {
        padding: 5px 0 15px;
    }
    a {
        color: #fff;
        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
    ul {
        li {
            padding: 5px 0;
        }
    }
    table {
        border: solid 1px #fff;
        padding: 3px;
        text-align: justify;
        width: 100%;
        color: #fff;
        font-size: 12px;
        margin: 1.5rem 0;
        font-family: "Montserrat";
        font-weight: bold;
        th {
            border: solid 1px #fff;
            text-align: center;
            padding: 10px;
        }
        td {
            border: solid 1px #fff;
            text-align: center;
            padding: 10px;
        }
    }
    img {
        width: 100%;
        max-width: 100px;
    }
}
