.containerRegister{
    background: linear-gradient(180deg, #0062B2 4.24%, #01B6ED 48.13%);
    width: 100%;
    min-height: calc(100vh - 150px);
    padding-bottom: 50px;
    position: relative;
    &--wall{
        // mix-blend-mode: multiply;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
        pointer-events: none;
    }
    .iconsRegister{
        position: absolute;
        width: 100%;
        height: 100%;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPap{
        position: absolute;
        left: 0 !important;
        top: 10% !important;
        width: 200px;
        @include mq(tab){
            display: none;
        }
    }
    &--iconPlat{
        position: absolute;
        right: 20px !important;
        left: unset !important;
        width: 150px;
        top: 5% !important;
        @include mq(tab){
            display: none;
        }
    }
    &--iconChi{
        position: absolute;
        right: 200px !important;
        left: unset !important;
        width: 150px;
        top: 52% !important;
        @include mq(tab){
            display: none;
        }
    }
    &__content{
        width: 100%;
        @include flex;
        flex-direction: column;
        &--title{
            font-weight: 700;
            font-size: 80px;
            line-height: 60px;
            color: $white;
            margin: 20px 0 30px;
            @include mq(tab){
                font-size: 32px;
                line-height: 60px;
                margin: 0px 0 10px;
            }
        }
        &--copy{
            font-size: 24px;
            line-height: 120%;
            color: #FFFFFF;
            position: relative;
            margin-bottom: 18px;
            font-family: 'Iron-Brine';
            z-index: +2;
            display: grid;
            @include mq(mobS,min){
                display: initial;
            }
            span{
                color: $yellowP;
                text-decoration: underline;
            }
            @include mq(tab){
                font-size: 16px;
                line-height: 120%;
                text-align: center;
                width: 80%;
            }
            &::after{
                content: '';
                display: none;
                width: 100%;
                height: 1px;
                background: $white;
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
}