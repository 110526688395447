.formG{
    // width: 60%;
    width: 90%;
    max-width: 860px;
    margin: 0 auto;
    z-index: 0;
    position: relative;
    @include mq(mob){
        width: 100%;
    }
    &--bk{
        position: absolute;
        left: -5%;
        width: 110%;
        height: 100%;
        top: 0;
        pointer-events: none;
        @include mq(mob,min) {
            width: 120%;
            height: 135%;
            top: -120px;
            left: -50px;
        }
        @include mq(tab,min) {
            top: -110px;
            left: -80px;
        }
        &.desktop {
            display: none;
            @include mq(mob,min) {
                display: block;
            }
        }
        &.mobile {
            @include mq(mob,min) {
                display: none;
            }
        }
    }
    &__content{
        width: 100%;
        @include flex(flex, space-around, center, center);
        position: relative; 
        padding-top: 30px;   
        padding-bottom: 30px;  
        @include mq(tab,min) {
            padding-bottom: 0;
        }
        &--input{
            width: 45%;
            @include flex(flex, center, flex-start, flex-start);
            flex-direction: column;
            margin: 15px 0;
            position: relative;
            z-index: 2;
            @include mq(mob){
                width: 95%;
            }
            label{
                font-size: 20px;
                line-height: 120%;
                color: $white;
                margin-bottom: 5px;
                margin-left: 10px;
                font-family: 'Anton';
            }
            input{
                width: 100%;
                // width: 350px;
                height: 40px;
                border: none;
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid $white;
                border-radius: 5px;
                padding: 0 10px;
                box-sizing: border-box;
                font-size: 15px;
                line-height: 150%;
                color: $white;
                font-family: 'Montserrat';
                &:focus-visible{
                    outline: none;
                }
                &::placeholder{
                    font-size: 15px;
                    line-height: 150%;
                    color: $white;
                    font-family: 'Montserrat';
                }
                &.is--invalid{
                    border-color: #FF1500;
                    color: #FF1500;
                    &::placeholder{
                        color: #FF1500;
                    }
                }
                @include mq(tab){
                    width: 100%;
                }
            }
            select{
                width: 100%;
                // width: 350px;
                height: 40px;
                border: none;
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid $white;
                border-radius: 5px;
                font-size: 15px;
                line-height: 150%;
                padding: 10px;
                color: $white;
                &.is--invalid{
                    border-color: #FF1500;
                    color: #FF1500;
                    &::placeholder{
                        color: #FF1500;
                    }
                }
                &:focus-visible{
                    outline: none;
                }
                option{
                    color: #444343;
                }
                @include mq(tab){
                    width: 100%;
                }
            }
            &.check{
                width: 100%;
                margin-left: 20px;
                position: relative;
                @include mq(mob){
                    width: 95%;
                }
                label{
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    font-family: 'Anton';
                }
                input{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                    &:checked ~ .checkmark {
                        // background-color: #2196F3;
                    }
                    &:checked ~ .checkmark:after {
                        display: block;
                    }
                }
                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: transparent;
                    border: 2px solid $white;
                    border-radius: 200px;
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                        left: 7px;
                        top: 2px;                    
                        width: 5px;
                        height: 10px;
                        border: solid $white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
                .copyCheck{
                    font-size: 14px;
                    line-height: 150%;
                    color: $white;
                    padding-left: 30px;
                    padding-right: 30px;
                    display: block;
                    span{
                        color: $yellowP;
                    }
                }
                .error-message {
                    left: 40px
                }
            }
            &.inputBorn{
                @include flex(flex, space-between, center, center);
                & .inputBorn__content{
                    width: 350px;
                    @include flex(flex, space-between, center, center);
                    & .inputBorn__day{
                        width: 30%;
                        input{
                            width: 100%;
                        }
                    }
                    & .inputBorn__month{
                        width: 30%;
                        input{
                            width: 100%;
                        }
                    }
                    & .inputBorn__year{
                        width: 30%;
                        input{
                            width: 100%;
                        }
                    }
                }
            }   
            .error-message{
                color: #FF1500;
                font-weight: 600;
                font-family: 'Montserrat';
                position: absolute;
                // bottom: -30px;
                bottom: -18px;
                left: 10px;
                font-size: 12px;
            }
        }
        &--btn{
            text-align: center;
            margin: 30px 0 60px;
            z-index: 2;
            position: relative;
            button{
                background: $yellowP;
                box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.3);
                border-radius: 20px;
                font-size: 22px;
                line-height: 30px;
                color: $blueP;
                text-transform: uppercase;
                padding: 10px 40px;
                border: none;
                width: 270px;
                height: 65px;
                cursor: pointer;
                box-shadow: 0px 10px 0px #aca003;
            }
        }
    }
    &__redimir {
        max-width: 1100px;
        @include mq(mob,min) {
            padding-top: 100px;
        }
        @include mq(tab,min) {
            padding-top: 140px;
            margin-top: -80px;
            // display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            // flex-wrap: nowrap;
            // display: grid;
            // grid-template-columns: 237px auto;
        }
        .formG--bk {
            @include mq(tab,min) {
                height: 160%;
            }
            .desktop {
                display: none;
                @include mq(mob,min) {
                    display: block;
                }
            }
            .mobile {
                @include mq(mob,min) {
                    display: none;
                }
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
        & .formG__content {
            padding-top: 70px;
            padding-bottom: 0;
            @include mq(mob,min) {
                padding-top: 0;
            }
            .inputBorn__content {
                width: 100%;
            }
            &--btn {
                width: 100%;
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                margin: 15px 0 55px;
                @include mq(tab,min) {
                    margin-bottom: 0;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                }
                .walletBtn {
                    display: grid;
                    color: $white;
                    margin-left: 15px;
                    font-family: 'Iron-Brine';
                    font-size: 24px;
                    line-height: 28px;
                    margin-bottom: 90px;
                    @include mq(tab,min) {
                        font-size: 32px;
                        line-height: 40px;
                        margin-bottom: 0;
                    }
                    span {
                        font-size: inherit;
                        color: $yellowP;
                        text-decoration: underline;
                    }
                }
            }
        }
        & .wallet {
            width: 95%;
        }
        .imgRedimir {
            display: none;
            width: 237px;
            padding-top: 35px;
            z-index: 1;
            // @include mq(tab,min) {
            //     display: block;
            // }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .containerRedimir {

        }
    }
}